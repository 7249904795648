import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConnectionTestService} from '../../../core/services/connection-test.service';

@Component({
  selector: 'app-connection-test',
  templateUrl: './connection-test.component.html',
  styleUrls: ['./connection-test.component.scss']
})
export class ConnectionTestComponent implements OnInit {

  credentialForm: FormGroup;
  hide = true;
  result: string;
  isLoading = false;
  failed = false;

  constructor(private fb: FormBuilder,
              private loginService: ConnectionTestService) {
    this.credentialForm = this.fb.group({
      usrId: [ null, Validators.required ],
      password: [ null, Validators.required ]
    });
  }

  ngOnInit(): void {
  }

  get formControls() {
    return this.credentialForm.controls;
  }

  login() {
    if (this.credentialForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.failed = false;
    const authData = window.btoa(this.formControls.usrId.value + ':' + this.formControls.password.value);
    this.loginService.connectionTest(authData).subscribe(
      response => {
        this.isLoading = false;
        this.result = response.response;
      }, error => {
        if (error.error.status === 401) {
          this.result = error.error.message;
        } else {
          this.result = 'An error has occurred. Please contact your administrator.';
        }
        this.isLoading = false;
        this.failed = true;
      }
    );
  }
}
