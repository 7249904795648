import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LandingComponent} from './landing/landing.component';
import {PagesComponent} from './pages/pages.component';
import {NotAuthorizedComponent} from '../shared/components/not-authorized/not-authorized.component';
import {NotFoundComponent} from '../shared/components/not-found/not-found.component';
import {AuthGuard} from '../core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./landing/landing.module').then(auth => auth.LandingModule)
      }
    ]
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(main => main.PagesModule)
      }
    ]
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
