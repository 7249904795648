import { Component, OnInit } from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {UserApplicationClient} from '../../../../shared/models/reporting/user-application-client.model';
import {Subject} from 'rxjs';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {UserService} from '../../../../core/services/user.service';
import {GenericFilterObject, Page} from '../../../../shared/models/reporting/generic-filter-object';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';
import {Summary} from '../../../../shared/models/reporting/summary.model';
import {SummaryService} from '../../../../core/services/summary.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.TASKS_SUMMARY.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: Summary[] = [];
  length = 0;

  dataSubject: Subject<Summary[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private msgBanner: MsgBannerService,
              private summaryService: SummaryService) {
    this.constructForm();
  }

  ngOnInit(): void {
    const genericFilterObj: GenericFilterObject = {
      form: null,
      page: new Page(0, 10, null)
    };
    this.getData(genericFilterObj);
  }

  constructForm() {
    this.form = this.fb.group({
      dateStart: new FormControl(),
      dateEnd: new FormControl()
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.summaryService.getSummary(genericFilterObj, COMPONENT_ROUTES.TASKS_SUMMARY.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: Summary[]) {
    this.dataSubject.next(data);
  }

  getExpandable(): boolean {
    return TABLE_CONTENTS.TASKS_SUMMARY.EXPANDABLE;
  }

}
