<div>
  <mat-card
    class="main-view"
  >
    <app-msg-banner
      *ngIf="showNotification"
      [msgList]="messageList"
      (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <app-generic-table
      [columns]="columns" [filterForm]="form"
      [expandable]="getCredits.EXPANDABLE" [displayFilters]="true"
      [parentSubject]="dataSubject" [length]="length"
      (filterEmitter)="refreshData($event)"
    ></app-generic-table>
  </mat-card>
</div>
