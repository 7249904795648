<div
  class="main-view"
>
  <mat-card
    fxFlex="50%"
    fxFlex.lt-md="80%"
    class="credential-card mat-elevation-z0"
    [formGroup]="credentialForm"
  >
    <mat-card-title>Credential Test</mat-card-title>

    <mat-card-content
      fxLayout="column"
      fxLayoutAlign="start"
      fxLayoutGap="10px"
    >
      <mat-form-field appearance="standard">
        <mat-label>User eID</mat-label>
        <input
          matInput
          type="text"
          formControlName="usrId"
          (keyup.enter)="login()"
        >
        <mat-error>User eID is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          (keyup.enter)="login()"
        >
        <button
          *ngIf="formControls.password.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
        >
          <mat-icon (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error>Password is required.</mat-error>
      </mat-form-field>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
      >
        <button
          fxFlex="100px"
          fxFlex.lt-md="100%"
          type="button"
          mat-raised-button
          class="btn login-button"
          [disabled]="credentialForm.invalid"
          (click)="login()"
        >LOGIN</button>

        <mat-progress-bar
          *ngIf="isLoading"
          mode="indeterminate"
        ></mat-progress-bar>

        <div
          *ngIf="!isLoading && result"
          fxShow
          fxHide.lt-md
          fxLayout="row"
          fxLayoutGap="5px"
          fxLayoutAlign="space-between center"
        >
          <span
            class="result"
            [ngClass]="{ 'success': !failed, 'fail': failed }"
          ></span>
          <span>{{ result }}</span>
        </div>
      </div>

      <div
        *ngIf="!isLoading && result"
        fxHide
        fxShow.lt-md
        fxLayout="row"
        fxLayoutGap="5px"
        fxLayoutAlign="center center"
      >
        <span
          class="result"
          [ngClass]="{ 'success': !failed, 'fail': failed }"
        ></span>
        <span>{{ result }}</span>
      </div>

    </mat-card-content>
  </mat-card>
</div>
