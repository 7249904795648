import {Injectable} from '@angular/core';
import {GenericFilterObject} from '../../shared/models/reporting/generic-filter-object';
import {Observable} from 'rxjs';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {CartonContent, ShipmentNotification} from '../../shared/models/reporting/shipment-notification.model';
import {UserCustomer} from '../../shared/models/reporting/user-customer.model';
import {PtmCustomerDetails} from '../../shared/models/reporting/ptm-customer-details.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentNotificationService {

  constructor(private http: HttpClient) { }

  getShipmentNotification(genericFilterObj: GenericFilterObject, path: string): Observable<IPagedResponse<ShipmentNotification>> {
    let params = null;

    params = new HttpParams();
    if (genericFilterObj.form != null) {
      Object.keys(genericFilterObj.form).map(function (key) {
        if (genericFilterObj.form[key] !== null && genericFilterObj.form[key] !== undefined && genericFilterObj.form[key] !== '') {
          params = params.append(key, genericFilterObj.form[key]);
        }
      });
    }

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size.toString());
      params = params.append('page', genericFilterObj.page.page.toString());

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    return this.http.get<IPagedResponse<ShipmentNotification>>(environment.apiResourceUri + path.substring(1), { params: params })
      .pipe(map((res: IPagedResponse<ShipmentNotification>) => {
        return res;
      }));
  }

  getCartonContent(genericFilterObj: GenericFilterObject, path: string, id: number): Observable<IPagedResponse<CartonContent>> {
    let params = new HttpParams();

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size.toString());
      params = params.append('page', genericFilterObj.page.page.toString());

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    return this.http.put<IPagedResponse<CartonContent>>(`${environment.apiResourceUri}${path.substring(1)}/carton-content`, id, {params: params})
      .pipe(map((res: IPagedResponse<CartonContent>) => {
        return res;
      }));
  }
}
