import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenericFilterObject} from '../../shared/models/reporting/generic-filter-object';
import {UserCustomer} from '../../shared/models/reporting/user-customer.model';
import {Observable} from 'rxjs';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {PtmCustomerDetails} from '../../shared/models/reporting/ptm-customer-details.model';
import {AllStores} from '../../shared/models/reporting/all-stores.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  getCustomers(genericFilterObj: GenericFilterObject, path: string, custs: UserCustomer[]): Observable<IPagedResponse<PtmCustomerDetails>> {
    let params = new HttpParams();

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size.toString());
      params = params.append('page', genericFilterObj.page.page.toString());

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    return this.http.put<IPagedResponse<PtmCustomerDetails>>(environment.apiResourceUri + 'customers' + path, custs, {params: params})
      .pipe(map((res: IPagedResponse<PtmCustomerDetails>) => {
        return res;
      }));
  }

  getAllCustomers<T>(genericFilterObj: GenericFilterObject, path: string): Observable<IPagedResponse<T>> {
    let params = new HttpParams();

    if (genericFilterObj.form != null) {
      const _this = this;
      Object.keys(genericFilterObj.form).map(function (key) {
        if (genericFilterObj.form[key] !== null && genericFilterObj.form[key] !== undefined && genericFilterObj.form[key] !== '') {
            params = params.append(key, genericFilterObj.form[key]);
          }
      });
    }

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size.toString());
      params = params.append('page', genericFilterObj.page.page.toString());

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    return this.http.get<IPagedResponse<T>>(environment.apiResourceUri + 'customers' + path, {params: params})
      .pipe(map((res: IPagedResponse<T>) => {
        return res;
      }));
  }
}
