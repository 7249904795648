import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from './loading-service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoadingService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes('auth/connection-test')) {
      this.loaderService.show();
      return next.handle(req).pipe(finalize(() => this.loaderService.hide()));
    }
    return next.handle(req);
  }
}
