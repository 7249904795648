import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {User} from '../../shared/models/user';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {GraphService} from '../../core/graph/graph.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Region} from '../../shared/constants/region';
import {environment} from '../../../environments/environment';
import {LogoService} from '../../core/services/logo.service';
import {Title} from '@angular/platform-browser';

const pageTitleRouteMap = {
  '/home': 'UK2GO Analytics Dashboard',
  '/connection-test': 'Test Connection',
  '/user-application-client': 'User Application Client Dashboard',
  '/ptm-assigned-stores': 'PTM\'s Assigned Stores Dashboard',
  '/reorders': 'Reorders Dashboard',
  '/negative-adjustments': 'Negative Adjustments Dashboard',
  '/positive-adjustments': 'Positive Adjustments Dashboard',
  '/empty-pkts': 'Empty Pockets Dashboard',
  '/credits': 'Credits Dashboard',
  '/transmissions': 'Transmissions Dashboard',
  '/shopback-loc': 'Shopback Location Dashboard',
  '/stock-counts': 'Stock Counts Dashboard',
  '/carton-put-away': 'Carton Put Away Dashboard',
  '/summary': 'UK2GO Tasks Summary Dashboard',
  '/delivery-confirmation': 'Delivery Confirmation',
  '/all-stores': 'All Stores',
};

@Component({
  selector: 'app-module-testing',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, AfterViewInit, AfterViewChecked {

  title = 'UK2GO Analytics';
  user: User = {
    displayName: '',
    groupIDs: [],
    currentPath: '/',
  };
  mode = 'side';

  switchForm: FormGroup;

  @ViewChild('drawer', { static: false }) drawer;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.adaptSidebar();
  }

  constructor(private authService: MsalService,
              private graphService: GraphService,
              private logoService: LogoService,
              private fb: FormBuilder,
              private titleService: Title,
              private router: Router,
              private cdr: ChangeDetectorRef) {
    let region: Region = null;
    this.persistRegion();

    if (localStorage.getItem('region') != null) {
      region = Number(localStorage.getItem('region'));
      if (region === Region.JS) {
        environment.apiResourceUri = environment.ausUrl;
        this.titleService.setTitle('JS2GO Analytics');
      } else if (region === Region.UK) {
        environment.apiResourceUri = environment.ukUrl;
        this.titleService.setTitle('UK2GO Analytics');
      }
    } else {
      region = Region.UK;
      environment.apiResourceUri = environment.ukUrl;
      this.titleService.setTitle('UK2GO Analytics');
    }
    this.switchForm = this.fb.group({
      switch: [ region, [] ]
    });

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.title = pageTitleRouteMap[event['url']]
            ? pageTitleRouteMap[event['url']]
            : (this.switchForm.controls.switch.value === Region.UK ? `UK` : 'JS') + '2GO Analytics Dashboard';
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = this.graphService.getUser();
    this.adaptSidebar();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.runOnRouteChange();

    this.drawer.opened = true;

    this.persistRegion();
    if (localStorage.getItem('region') != null) {
      const region = Number(localStorage.getItem('region'));
      this.logoService.switchLogo(region);
      if (region === Region.JS) {
        environment.apiResourceUri = environment.ausUrl;
        this.titleService.setTitle('JS2GO Analytics');
      } else if (region === Region.UK) {
        environment.apiResourceUri = environment.ukUrl;
        this.titleService.setTitle('UK2GO Analytics');
      }
    } else {
      this.logoService.switchLogo(Region.UK);
      environment.apiResourceUri = environment.ukUrl;
      this.titleService.setTitle('UK2GO Analytics');
    }

    this.changeTitle();

    // this.switchForm.controls.switch.valueChanges.subscribe(value => {
    //   if (value === Region.JS) {
    //     environment.apiResourceUri = environment.ausUrl;
    //     this.logoService.switchLogo(Region.JS);
    //     localStorage.setItem('region', Region.JS.toString());
    //   } else if (value === Region.UK) {
    //     environment.apiResourceUri = environment.ukUrl;
    //     this.logoService.switchLogo(Region.UK);
    //     localStorage.setItem('region', Region.UK.toString());
    //   }
    //
    //   this.changeTitle();
    //   window.location.reload();
    // });
  }

  persistRegion() {
    const url = window.location.href;
    if (url.includes('aus') || url.includes('js2go') || url.includes('johnsands')) {
      localStorage.setItem('region', Region.JS.toString());
      this.titleService.setTitle('JS2GO Analytics');
    } else {
      localStorage.setItem('region', Region.UK.toString());
      this.titleService.setTitle('UK2GO Analytics');
    }
  }

  get getRegion() {
    return Region;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      /* const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const ps = new PerfectScrollbar(elemMainPanel);
        ps.update();*/
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  close(reason: string) {
    console.log(reason);
    this.drawer.close();
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: 'landing'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  private adaptSidebar() {
    if (window.innerWidth <= 991) {
      this.mode = 'over';
    } else {
      this.mode = 'side';
    }
  }

  private changeTitle() {
    if (this.router.url.endsWith('/home')) {
      this.title = (this.switchForm.controls.switch.value === Region.UK ? `UK` : 'JS') + '2GO Analytics Dashboard';
    } else if (this.router.url.endsWith('/summary')) {
      this.title = (this.switchForm.controls.switch.value === Region.UK ? `UK` : 'JS') + '2GO Tasks Summary Dashboard';
    }
  }
}
