import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import * as config from '../../modules/app-config.json';
import {User} from '../../shared/models/user';
import {MsalService} from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  user: User = {
    displayName: '',
    groupIDs: [],
    currentPath: '/'
  };

  private subject = new Subject<User>();
  graphUri = config.resources.graphApi.resourceUri;

  sendClickEvent(user: User) {
    this.subject.next(user);
  }

  getClickEvent(): Observable<User> {
    return this.subject.asObservable();
  }

  constructor(private http: HttpClient,
              private authService: MsalService) {}

  getGroups() {
    return this.http.get(this.graphUri);
  }

  getNextPage(nextPage) {
    return this.http.get(nextPage);
  }

  getUser() {
    if (this.user.displayName.length === 0) {
      const accounts = this.authService.instance.getAllAccounts();

      if (accounts.length > 0) {
        this.user.displayName = accounts[0].idTokenClaims['preferred_username'];
        if (accounts[0].idTokenClaims['groups']) {
          this.user.groupIDs = <string[]>(
            (<unknown>accounts[0].idTokenClaims['groups'])
          );
        }
      }
    }
    return this.user;
  }
}
