import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {GenericFilterObject} from '../../../../shared/models/reporting/generic-filter-object';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';
import {ShipmentNotification} from '../../../../shared/models/reporting/shipment-notification.model';
import {ShipmentNotificationService} from '../../../../core/services/shipment-notification.service';

@Component({
  selector: 'app-shipment-notification',
  templateUrl: './shipment-notification.component.html',
  styleUrls: ['./shipment-notification.component.scss']
})
export class ShipmentNotificationComponent implements OnInit, AfterViewInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.SHP_NOTIF.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: ShipmentNotification[] = [];
  length = 0;

  dataSubject: Subject<ShipmentNotification[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private msgBanner: MsgBannerService,
              public shipmentNotificationService: ShipmentNotificationService) {
    this.constructForm();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.openSnackBar();
    this.notifyChild([]);
  }

  constructForm() {
    this.form = this.fb.group({
      shpNotifId: [],
      chainStore: [],
      shpNotifType: [],
      order: [],
      invoice: []
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.shipmentNotificationService.getShipmentNotification(genericFilterObj, COMPONENT_ROUTES.SHP_NOTIF.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: ShipmentNotification[]) {
    this.dataSubject.next(data);
  }

  get getShipmentNotifications() {
    return TABLE_CONTENTS.SHP_NOTIF;
  }

  get getShipmentNotificationRoute() {
    return COMPONENT_ROUTES.SHP_NOTIF;
  }

  openSnackBar() {
    this.snackBar.open('Please filter your results.', 'Close', {
      duration: 10 * 1000,
    });
  }

}
