import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {DialogData} from '../../models/reporting/dialog-data.model';
import {Subject} from 'rxjs';
import {GenericFilterObject, Page} from '../../models/reporting/generic-filter-object';

@Component({
  selector: 'app-generic-table-dialog',
  templateUrl: './generic-table-dialog.component.html',
  styleUrls: ['./generic-table-dialog.component.scss']
})
export class GenericTableDialogComponent implements OnInit {

  dataSubject: Subject<any[]> = new Subject();
  data: any[] = [];
  length = 0;

  // error list
  messageList = [];
  showNotification = false;

  constructor(public dialogRef: MatDialogRef<GenericTableDialogComponent>,
              private msgBanner: MsgBannerService,
              @Inject(MAT_DIALOG_DATA) public dataDialog: DialogData) { }

  ngOnInit(): void {
    const genericFilterObj: GenericFilterObject = {
      form: null,
      page: new Page(0, 10, null)
    };
    this.getData(genericFilterObj);
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.dataDialog.loadDataCallback(genericFilterObj, this.dataDialog.path, this.dataDialog.ids).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: any[]) {
    this.dataSubject.next(data);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
