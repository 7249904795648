import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {UtilsService} from '../services/utils.service';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value != null) {
      return super.transform(UtilsService.dateFromServer(value), environment.dateFormatUK);
    } else {
      return '';
    }
  }
}
