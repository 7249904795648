import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenericFilterObject} from '../../shared/models/reporting/generic-filter-object';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {map} from 'rxjs/operators';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private datePipe: DatePipe) { }

  getUsers<T>(genericFilterObj: GenericFilterObject, path: string): Observable<IPagedResponse<T>> {
    let params = null;

    params = new HttpParams();
    if (genericFilterObj.form != null) {
      const _this = this;
      Object.keys(genericFilterObj.form).map(function (key) {
        if (genericFilterObj.form[key] !== null && genericFilterObj.form[key] !== undefined && genericFilterObj.form[key] !== '') {
          if (key === 'created' || key === 'updated') {
            params = params.append(key, _this.datePipe.transform(genericFilterObj.form[key], environment.dateFormatUK));
          } else {
            params = params.append(key, genericFilterObj.form[key]);
          }
        }
      });
    }

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size);
      params = params.append('page', genericFilterObj.page.page);

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', 10);
      params = params.append('page', 0);
    }

    return this.http.get<IPagedResponse<T>>(environment.apiResourceUri + 'users' + path, {params: params})
      .pipe(map((res: IPagedResponse<T>) => {
        return res;
      }));
  }
}
