<mat-drawer-container
  class="wrapper"
>
  <mat-drawer
    #drawer
    class="example-sidenav"
    [mode]="mode"
  >
    <div class="sidebar" data-color="blue" data-background-color="white">
      <app-sidebar [drawer]="drawer"></app-sidebar>
      <div class="sidebar-background"></div>
    </div>
  </mat-drawer>
  <mat-toolbar
    class="toolbar-color"
  >
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        class="app-link"
      >
        <button mat-button (click)="drawer.toggle()">
          <i class="fa fa-bars" style="font-size: 28px"></i>
        </button>
        <a
          fxHide.xs
          fxShow.gt-xs
          mat-button
          routerLink="/"
          routerLinkActive="active"
        >{{ title }}</a>
        <a
          fxShow.xs
          fxHide.gt-xs
          style="font-size: 17px"
          mat-button
          routerLink="/"
          routerLinkActive="active"
        >{{ title }}</a>
      </div>

      <div>
        <button
          *ngIf="false"
          mat-button
          class="white-icon"
          [matMenuTriggerFor]="switchMenu"
        >Region</button>
        <mat-menu
          #switchMenu="matMenu"
          [formGroup]="switchForm"
        >
          <span style="margin: 8px">Choose a region:</span>
          <mat-radio-group
            fxLayout="column"
            fxLayoutGap="8px"
            formControlName="switch"
            style="margin-top: 8px"
          >
            <mat-radio-button [value]="getRegion.UK">UK</mat-radio-button>
            <mat-radio-button [value]="getRegion.JS">JS</mat-radio-button>
          </mat-radio-group>
        </mat-menu>

        <button
          fxHide.xs
          mat-icon-button
          class="white-icon"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <span>{{ user.displayName }} </span>
          </button>
        </mat-menu>

        <button
          fxHide.xs
          mat-raised-button
          color="warn"
          (click)="logout()"
        >
          Logout
        </button>
      </div>
    </div>
  </mat-toolbar>

  <div
    class="page-view"
  >
    <router-outlet
      style="text-align: center"
    ></router-outlet>
  </div>

</mat-drawer-container>
