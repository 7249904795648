import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {GenericFilterObject, Page} from '../../../../shared/models/reporting/generic-filter-object';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';
import {ShpbkLoc} from '../../../../shared/models/reporting/shpbk-loc.model';
import {ShpbkLocService} from '../../../../core/services/shpbk-loc.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-shpbk-loc',
  templateUrl: './shpbk-loc.component.html',
  styleUrls: ['./shpbk-loc.component.scss']
})
export class ShpbkLocComponent implements OnInit, AfterViewInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.SHOP_BACK_LOCATION.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: ShpbkLoc[] = [];
  length = 0;

  dataSubject: Subject<ShpbkLoc[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private msgBanner: MsgBannerService,
              private shpbkLocService: ShpbkLocService,
              private snackBar: MatSnackBar) {
    this.constructForm();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.openSnackBar();
    this.notifyChild([]);
  }

  constructForm() {
    this.form = this.fb.group({
      chainStore: [],
      upc: [],
      cabPkt: [],
      posr: []
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.shpbkLocService.getShpbkLocs(genericFilterObj, COMPONENT_ROUTES.SHOP_BACK_LOCATION.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: ShpbkLoc[]) {
    this.dataSubject.next(data);
  }

  getExpandable(): boolean {
    return TABLE_CONTENTS.USER_APP_CLIENT.EXPANDABLE;
  }

  openSnackBar() {
    this.snackBar.open('Shopback Location contains over two million of records. Please filter your results.', 'Close', {
      duration: 10 * 1000,
    });
  }
}
