<div class="ghost-ui">
  <table
    class="full-width-table"
    mat-table
    matSort
    [dataSource]="dataSource"
  >
    <ng-container class='member-item' [matColumnDef]="col.colDef" *ngFor="let col of columns">
      <th mat-header-cell *matHeaderCellDef>
        {{ col.colHeader }}
      </th>

      <td mat-cell *matCellDef="let element">
        <div class='member-item shimmer'></div>
      </td>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
