// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  baseHref: '/reporting/',
  clientId: '6db77307-8fbc-47de-95da-01c54f520693',
  backendApiScope: 'api://44e5e7c9-a00d-4ccc-abcf-7cb27dbd79ba/access_as_user',
  apiResourceUri: 'https://reporting-api-uk.qa.ukg2go.agpre.net/api/',
  dateFormatUK: 'dd/MM/yyyy',
  dateTimeFormatUK: 'dd/MM/yyyy HH:mm:ss',
  groups: {
    DEFAULT: '32fa47a6-3b6b-4c37-bd6a-8dd3a45325b6'
  },
  ukUrl: 'https://reporting-api-uk.qa.ukg2go.agpre.net/api/',
  ukLogoPath: 'assets/img/ukg-logo-min.png',
  ausUrl: 'https://reporting-api-aus.qa.ukg2go.agpre.net/api/',
  ausLogoPath: 'assets/img/js-logo-min.png'
};
