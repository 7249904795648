<div *ngIf="permissionsReceived">
  <div class="logo">
    <a href="" class="simple-text">
      <div class="logo-img">
        <img [src]="logoPath" />
      </div>
    </a>
  </div>
  <div
    class="sidebar-wrapper"
    fxLayout="column"
    fxLayoutAlign="space-between"
  >
    <mat-card class="login" *ngIf="!loggedIn">
      <b>Please log in to access the application.</b>
    </mat-card>
    <mat-accordion class="example-headers-align" multi *ngIf="loggedIn">
      <div *ngFor="let menuItem of menuItems">
        <mat-expansion-panel *ngIf="menuItem.childs.length > 1" [expanded]="menuItem.expanded">
          <mat-expansion-panel-header (click)="toggle(menuItem)">
            <mat-panel-title class="mat-panel-title">
              <mat-icon>{{ menuItem.icon }}</mat-icon>
              <p class="mat-panel-title-text">{{ menuItem.title }}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ul class="nav">
            <div *ngFor="let item of menuItem.childs">
              <mat-expansion-panel *ngIf="item.childs.length > 0"  class="{{ item.class }}" [expanded]="item.expanded">
                <mat-expansion-panel-header (click)="toggleChild(item)">
                  <mat-panel-title class="mat-panel-title">
                    <mat-icon>{{ menuItem.icon }}</mat-icon>
                    <p class="mat-panel-title-text">{{ item.title }}</p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <li
                  routerLinkActive="active"
                  *ngFor="let childItem of item.childs"
                  class="{{ childItem.class }} nav-item"
                >

                  <a
                    class="nav-link"
                    [routerLink]="[childItem.path]"
                    (click)="drawer.toggle()"
                  >
                    <i class="material-icons">{{ childItem.icon }}</i>
                    <p>{{ childItem.title }}</p>
                  </a>
                </li>
              </mat-expansion-panel>
              <li *ngIf="item.childs.length == '0'"
                  routerLinkActive="active"
                  class="{{ menuItem.class }} nav-item"
              >

                <a
                  class="nav-link"
                  [routerLink]="[item.path]"
                  (click)="drawer.toggle()"
                >
                  <i class="material-icons">{{ item.icon }}</i>
                  <p class="mat-panel-title-text">{{ item.title }}</p>
                </a>
              </li>
            </div>
          </ul>
        </mat-expansion-panel>
        <ul class="nav" *ngIf="menuItem.childs.length == 1">
          <li
            routerLinkActive="active"
            *ngFor="let item of menuItem.childs"
            class="{{ menuItem.class }} nav-item"
          >
            <a
              class="nav-link"
              [routerLink]="[item.path]"
              (click)="drawer.toggle()"
            >
              <i class="material-icons">{{ menuItem.icon }}</i>
              <p>{{ menuItem.title }}</p>
            </a>
          </li>
        </ul>
      </div>
    </mat-accordion>

    <ul
      fxShow.xs
      fxHide.gt-xs
      class="nav"
    >
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer;"
          (click)="logout()"
        >
          <i class="material-icons">logout</i>
          <p>Logout</p>
        </a>
      </li>
    </ul>
  </div>
</div>
