import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {HomePageComponent} from './home-page/home-page.component';
import {MsalGuard} from '@azure/msal-angular';
import {AuthGuard} from '../../core/guards/auth.guard';
import {UserAppClientComponent} from './reports/user-app-client/user-app-client.component';
import {COMPONENT_ROUTES} from '../../shared/constants/routes';
import {PtmAssignedStoresComponent} from './reports/ptm-assigned-stores/ptm-assigned-stores.component';
import {ReordersComponent} from './reports/reorders/reorders.component';
import * as config from '../app-config.json';
import {GroupGuard} from '../../core/guards/group.guard';
import {environment} from '../../../environments/environment';
import {NegativeAdjustmentsComponent} from './reports/negative-adjustments/negative-adjustments.component';
import {PositiveAdjustmentsComponent} from './reports/positive-adjustments/positive-adjustments.component';
import {EmptyPktComponent} from './reports/empty-pkt/empty-pkt.component';
import {CreditComponent} from './reports/credit/credit.component';
import {TransmissionComponent} from './reports/transmission/transmission.component';
import {ShpbkLocComponent} from './reports/shpbk-loc/shpbk-loc.component';
import {StockCountsComponent} from './reports/stock-counts/stock-counts.component';
import {CartonPutAwayComponent} from './reports/carton-put-away/carton-put-away.component';
import {SummaryComponent} from './reports/summary/summary.component';
import {ShipmentNotificationComponent} from './reports/shipment-notification/shipment-notification.component';
import {ConnectionTestComponent} from './connection-test/connection-test.component';
import {AllStoresComponent} from './reports/all-stores/all-stores.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.CONNECTION_TEST.PATH,
    component: ConnectionTestComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.USER_APP_CLIENT.PATH,
    component: UserAppClientComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.PTM_ASSIGNED_STORES.PATH,
    component: PtmAssignedStoresComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.REORDERS.PATH,
    component: ReordersComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.NEGATIVE_ADJ.PATH,
    component: NegativeAdjustmentsComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.POSITIVE_ADJ.PATH,
    component: PositiveAdjustmentsComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.EMPTY_PKTS.PATH,
    component: EmptyPktComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.CREDITS.PATH,
    component: CreditComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.TRANSMISSIONS.PATH,
    component: TransmissionComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.SHOP_BACK_LOCATION.PATH,
    component: ShpbkLocComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.STOCK_COUNTS.PATH,
    component: StockCountsComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.CARTON_PUT_AWAY.PATH,
    component: CartonPutAwayComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.TASKS_SUMMARY.PATH,
    component: SummaryComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.SHP_NOTIF.PATH,
    component: ShipmentNotificationComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
  {
    path: COMPONENT_ROUTES.ALL_STORES.PATH,
    component: AllStoresComponent,
    canActivate: [AuthGuard, GroupGuard, MsalGuard],
    data: {
      expectedGroup: environment.groups.DEFAULT
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRouting { }
