import {Component, Input, OnInit} from '@angular/core';
import {ColumnDefinition} from '../../constants/table-contents';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-ghost-table',
  templateUrl: './ghost-table.component.html',
  styleUrls: ['./ghost-table.component.scss']
})
export class GhostTableComponent implements OnInit {

  @Input() displayedColumns: string[];
  @Input() columns: ColumnDefinition[];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  constructor() { }

  ngOnInit(): void {
  }

}
