import { Component, OnInit } from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GenericUser} from '../../../../shared/models/reporting/generic-user.model';
import {Subject} from 'rxjs';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {CreditService} from '../../../../core/services/credit.service';
import {UserService} from '../../../../core/services/user.service';
import {GenericFilterObject, Page} from '../../../../shared/models/reporting/generic-filter-object';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';
import {TransmissionService} from '../../../../core/services/transmission.service';

@Component({
  selector: 'app-transmission',
  templateUrl: './transmission.component.html',
  styleUrls: ['./transmission.component.scss']
})
export class TransmissionComponent implements OnInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.TRANSMISSIONS.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: GenericUser[] = [];
  length = 0;

  dataSubject: Subject<GenericUser[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private msgBanner: MsgBannerService,
              public transmissionService: TransmissionService,
              private userService: UserService) {
    this.constructForm();
  }

  ngOnInit(): void {
    const genericFilterObj: GenericFilterObject = {
      form: null,
      page: new Page(0, 10, null)
    };
    this.getData(genericFilterObj);
  }

  constructForm() {
    this.form = this.fb.group({
      usrId: []
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.transmissionService.getTransmissions(genericFilterObj, COMPONENT_ROUTES.TRANSMISSIONS.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: GenericUser[]) {
    this.dataSubject.next(data);
  }

  get getTransmissions() {
    return TABLE_CONTENTS.TRANSMISSIONS;
  }

}
