import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {GraphService} from '../../../core/graph/graph.service';
import {MsalService} from '@azure/msal-angular';
import {COMPONENT_ROUTES} from '../../constants/routes';
import {LogoService} from '../../../core/services/logo.service';
import {Region} from '../../constants/region';
import {environment} from '../../../../environments/environment';

declare const $: any;

declare interface ParentRouteInfo {
  title: string;
  path: string;
  icon: string;
  class: string;
  expanded: Boolean;
  childs: RouteInfo[];
}

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  expanded: Boolean;
  childs: RouteInfo[];
  class: string;
}

export const ROUTES: ParentRouteInfo[] = [
  {
    title: COMPONENT_ROUTES.CONNECTION_TEST.NAME,
    icon: 'vpn_key',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.CONNECTION_TEST.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.USER_APP_CLIENT.NAME,
    icon: 'person',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.USER_APP_CLIENT.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.PTM_ASSIGNED_STORES.NAME,
    icon: 'storefront',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.PTM_ASSIGNED_STORES.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.ALL_STORES.NAME,
    icon: 'storefront',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.ALL_STORES.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.REORDERS.NAME,
    icon: 'reorder',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.REORDERS.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.SHOP_BACK_LOCATION.NAME,
    icon: 'pin_drop',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.SHOP_BACK_LOCATION.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.NEGATIVE_ADJ.NAME,
    icon: 'remove',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.NEGATIVE_ADJ.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.POSITIVE_ADJ.NAME,
    icon: 'add',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.POSITIVE_ADJ.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.EMPTY_PKTS.NAME,
    icon: 'account_balance_wallet',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.EMPTY_PKTS.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.CREDITS.NAME,
    icon: 'account_balance',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.CREDITS.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.TRANSMISSIONS.NAME,
    icon: 'podcasts',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.TRANSMISSIONS.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.STOCK_COUNTS.NAME,
    icon: 'inventory_2',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.STOCK_COUNTS.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.CARTON_PUT_AWAY.NAME,
    icon: 'loyalty',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.CARTON_PUT_AWAY.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.TASKS_SUMMARY.NAME,
    icon: 'checklist',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.TASKS_SUMMARY.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
  {
    title: COMPONENT_ROUTES.SHP_NOTIF.NAME,
    icon: 'local_shipping',
    path: '',
    class: '',
    expanded: false,
    childs: [
      {
        path: COMPONENT_ROUTES.SHP_NOTIF.PATH,
        title: '',
        icon: '',
        class: '',
        expanded: false,
        childs: []
      }
    ]
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input('drawer') drawer;

  logoPath: string = environment.ukLogoPath;
  menuItems: ParentRouteInfo[];
  public permissionsReceived = true;
  loggedIn = false;
  panelOpenState = false;

  clickEventSubscription: Subscription;

  constructor(private graphService: GraphService,
              private logoService: LogoService,
              private authService: MsalService) {
    this.clickEventSubscription = this.graphService
      .getClickEvent()
      .subscribe((response) => {
        this.loggedIn = true;
      });

    this.logoService.getSwitchEvent().subscribe(value => {
      if (value === Region.JS) {
        this.logoPath = environment.ausLogoPath;
        ROUTES[ROUTES.length - 1].title = ROUTES[ROUTES.length - 1].title.replace('UK', 'JS');
      } else if (value === Region.UK) {
        this.logoPath = environment.ukLogoPath;
        ROUTES[ROUTES.length - 1].title = ROUTES[ROUTES.length - 1].title.replace('JS', 'UK');
      }
    });
  }

  ngOnInit() {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts?.length > 0) {
      this.loggedIn = true;
    }
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  checkOpened() {
    if (this.drawer.opened === true) {
      (document.querySelector('.main-panel') as HTMLElement).style.cssFloat =
        'right';
      (document.querySelector('.main-panel') as HTMLElement).style.width =
        'calc(100% - 280px)';
    } else {
      (document.querySelector('.main-panel') as HTMLElement).style.cssFloat =
        'left';
      (document.querySelector('.main-panel') as HTMLElement).style.width =
        '100%';
    }
  }

  closeSideBar(): void {
    // this.appComponent.toggleSideBar();
  }

  toggle(item) {
    for (let i = 0; i < ROUTES.length; i++) {
      if (ROUTES[i].title === item.title) {
        ROUTES[i].expanded = !ROUTES[i].expanded;
        continue;
      }
      ROUTES[i].expanded = false;
    }
  }

  toggleChild(item) {
    for (let i = 0; i < ROUTES.length; i++) {
      for (let j = 0; j < ROUTES[i].childs.length; j++) {
        if (ROUTES[i].childs[j] === item) {
          ROUTES[i].childs[j].expanded = !ROUTES[i].childs[j].expanded;
          continue;
        }
        ROUTES[i].childs[j].expanded = false;
      }
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: 'landing'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
}
