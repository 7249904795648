import {Injectable} from '@angular/core';
import {MsgType} from './msg-type';
import {Message} from './message.model';

@Injectable()
export class MsgBannerService {

  /**
   * Displays a message on the UI of one of the following types: SUCCESS, WARNING, ERROR.
   * The message has a button on the right side that when clicked will remove the message
   * Use Examples:
   * this.msgBanner.addMessage(this.messageList, 'Example of success message!', MsgType.SUCCESS);
   * this.msgBanner.addMessage(this.messageList, 'Example of warning message!', MsgType.WARNING);
   * this.msgBanner.addMessage(this.messageList, 'Example of error message!', MsgType.ERROR);
   *
   * @param targetList the array of messages. A variable that needs to be defined on the component .ts
   * @param text text to display on the banner
   * @param type type of the message which is one of the following types: SUCCESS, WARNING, ERROR
   */
  public addMessage(targetList: Message[], text: string, type: MsgType) {
    const errMsg = new Message();
    errMsg.content = text;
    errMsg.type = type;
    errMsg.disabled = false;
    if (targetList.filter(item => item.content === errMsg.content).length === 0) {
      targetList.push(errMsg);
    }
  }

  /**
   * Displays a success message on the UI.
   * Example: this.msgBanner.addMsgSuccess(this.messageList, 'Example of success message!');
   *
   * @param targetList the array of messages. A variable that needs to be defined on the component .ts
   * @param text text to display on the banner
  */
  public addMsgSuccess(targetList: Message[], text: string) {
    this.addMessage(targetList, text, MsgType.SUCCESS);
  }

  /**
   * Displays an warning message on the UI.
   * Example: this.msgBanner.addMsgWarning(this.messageList, 'Example of warning message!');
   *
   * @param targetList the array of messages. A variable that needs to be defined on the component .ts
   * @param text text to display on the banner
  */
  public addMsgWarning(targetList: Message[], text: string) {
    this.addMessage(targetList, text, MsgType.WARNING);
  }

  /**
   * Displays an error message on the UI.
   * Example: this.msgBanner.addMsgError(this.messageList, 'Example of error message!');
   *
   * @param targetList the array of messages. A variable that needs to be defined on the component .ts
   * @param text text to display on the banner
  */
  public addMsgError(targetList: Message[], text: string) {
    this.addMessage(targetList, text, MsgType.ERROR);
  }
}
