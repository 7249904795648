import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StringResponse} from '../../shared/models/string-response';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConnectionTestService {

  constructor(private http: HttpClient) { }

  connectionTest(auth: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${environment.apiResourceUri}auth/connection-test`, auth);
  }
}
