import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  // for creating/updating/sending to server purpose
  public static dateToServerDate(dateTime: any) {
    const localDate = new Date(dateTime);
    const offset = moment(localDate).utcOffset();
    return moment(localDate).add(offset, 'm').toDate();
  }

  // for displaying purpose
  public static dateFromServer(dateTime: any) {
    const offset = new Date(dateTime).getTimezoneOffset();
    const serverDate = new Date(dateTime);
    return moment(serverDate).add(offset, 'm').toDate();
  }
}
