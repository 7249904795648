import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GraphService} from './graph/graph.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [CommonModule],
  providers: [
    GraphService
  ],
})
export class CoreModule { }
