import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Message } from './message.model';

@Component({
  selector: 'app-msg-banner',
  templateUrl: './msg-banner.component.html',
  styleUrls: ['./msg-banner.component.scss']
})
export class MsgBannerComponent implements OnInit, OnChanges {
  private messageListValue: Message[];
  @Input()
  get msgList() {
    return this.messageListValue;
  }
  @Output() msgListChange = new EventEmitter<Message[]>();
  set msgList(val: Message[]) {
    this.messageListValue = val;
    this.msgListChange.emit(this.messageListValue);
  }

  constructor() { }

  ngOnInit(): void {
    this.messageListValue = this.messageListValue.map((item: Message) => {
      if (item.disabled == undefined) {
        item.disabled = false;
      }
      return item;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
  }

  removeMsg(msg: Message) {
    if (!msg.disabled) {
      this.msgList  = this.msgList.filter(item => item.content !== msg.content);
    }
  }

  getMsgText(type: string): string {
    if (type == 'S') {
      return 'SUCCESS';
    } else if (type == 'E') {
      return 'ERROR';
    } else if (type == 'W') {
      return 'WARNING';
    } else if (type == 'I') {
      return 'INFO';
    }
    return '';
  }

  getButtonClass(type: string): string {
    var className = 'badge ';
    if (type == 'S') {
      className += 'badge-success';
    } else if (type == 'E') {
      className += 'badge-danger';
    } else if (type == 'W') {
      className += 'badge-warning';
    } else if (type == 'I') {
      className += 'badge-info';
    }
    return className;
  }

  getTextClass(type: string): string {
    var className = 'message-text ';
    if (type == 'S') {
      className += 'message-success';
    } else if (type == 'E') {
      className += 'message-danger';
    } else if (type == 'W') {
      className += 'message-warning';
    } else if (type == 'I') {
      className += 'message-info';
    }
    return className;
  }
}
