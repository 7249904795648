<form
  *ngIf="displayFilters"
  [formGroup]="filterForm"
>
  <mat-card
    class="no-padding mat-elevation-z0"
  >
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="true"
      >
        <mat-expansion-panel-header style="height: auto !important;">
          <span class="material-icons filter-title">filter_alt</span>
          <span class="filter-title"> Filter </span>
        </mat-expansion-panel-header>

        <div
          fxLayout="row wrap"
          fxLayoutGap="3%"
          fxLayout.lt-md="column"
          fxLayoutGap.lt-md="0%"
        >
          <mat-form-field
            *ngFor="let col of filters"
            fxFlex="30%"
            fxFlex.lt-md="100%"
          >
            <!-- Simple Input -->
            <input
              *ngIf="col.colType === getColumnType.STRING || col.colType === getColumnType.DATE_TIME"
              matInput
              (keydown.enter)="applyFilters()"
              [formControlName]="col.colDef"
              [placeholder]="col.colHeader"
            />

            <!-- Date Range -->
              <mat-label
                *ngIf="col.colType === getColumnType.DATE_RANGE"
              >{{ col.colHeader }}</mat-label>
              <mat-date-range-input
                *ngIf="col.colType === getColumnType.DATE_RANGE"
                [rangePicker]="dateRangePicker"
              >
                <input matStartDate formControlName="dateStart" placeholder="Start date">
                <input matEndDate formControlName="dateEnd" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle
                *ngIf="col.colType === getColumnType.DATE_RANGE"
                matSuffix [for]="dateRangePicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker></mat-date-range-picker>


            <!-- Date -->
            <input
              *ngIf="col.colType === getColumnType.DATE"
              matInput
              (keydown.enter)="applyFilters()"
              [formControlName]="col.colDef"
              [placeholder]="col.colHeader"
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              *ngIf="col.colType === getColumnType.DATE"
              matSuffix [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-icon
              class="close-filter-icon"
              *ngIf="filterForm.controls[col.colDef]?.value?.length > 0"
              (click)="filterForm.controls[col.colDef].reset()"
              matSuffix
            >close</mat-icon>

            <mat-hint
              *ngIf="col.filterHint"
            >{{ col.filterHint }}</mat-hint>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayout.lt-md="column"
        >
          <button
            fxFlex="100px"
            fxFlex.lt-md="100%"
            type="button"
            mat-raised-button
            class="btn submit-button"
            (click)="applyFilters()"
          > SEARCH </button>
          <button
            fxFlex="100px"
            fxFlex.lt-md="100%"
            type="button"
            mat-raised-button
            class="btn reset-button"
            (click)="filterForm.reset()"
          > CLEAR </button>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
</form>

<mat-card
  class="no-padding mat-elevation-z1"
>
  <app-ghost-table
    *ngIf="reload"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
  ></app-ghost-table>

  <div
    [ngStyle]="{ 'display': reload === false ? '' : 'none'}"
    class="table-overflow"
    [ngClass]="{ 'fixed-height': !displayFilters }"
  >
    <table
      mat-table
      matSort
      (matSortChange)="changeSort($event)"
      [dataSource]="dataSource"
    >
      <ng-container [matColumnDef]="col.colDef" *ngFor="let col of columns">
        <!-- Column Header -->
        <th class="{{ col.colWidthClass }}" mat-sort-header mat-header-cell *matHeaderCellDef>
          {{ col.colHeader }}
        </th>

        <!-- Column Content -->
        <span *ngIf="col.colType === getColumnType.DATE">
          <td mat-cell *matCellDef="let element">
            {{ element[col.colDef] | dateFormat }}
          </td>
        </span>
        <span *ngIf="col.colType === getColumnType.DATE_TIME">
          <td mat-cell *matCellDef="let element">
            {{ element[col.colDef] | dateTimeFormat }}
          </td>
        </span>
        <span *ngIf="col.colType === getColumnType.URL">
          <td mat-cell *matCellDef="let element">
            <button
              mat-raised-button
              class="btn submit-button"
              (click)="openUrl(element[col.colDef])"
            >Track</button>
          </td>
        </span>
        <span *ngIf="col.colType !== getColumnType.DATE && col.colType !== getColumnType.DATE_TIME && col.colType !== getColumnType.URL">
          <td mat-cell *matCellDef="let element">
            {{ element[col.colDef] }}
          </td>
        </span>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        [ngStyle]="{ 'cursor': expandable ? 'pointer' : '' }"
        *matRowDef="let row; columns: displayedColumns;"
        (click)="openDetails(row)"
      ></tr>

    </table>
  </div>

  <mat-paginator
    [length]="length"
    [pageSizeOptions]="[ 10, 20, 50, 100, 250 ]"
    [pageSize]="10"
    (page)="onPageChange($event)"
  ></mat-paginator>
</mat-card>
