import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ColumnDefinition, ColumnType, TABLE_CONTENTS} from '../../constants/table-contents';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FormGroup} from '@angular/forms';
import {GenericFilterObject, Page} from '../../models/reporting/generic-filter-object';
import {Subject} from 'rxjs';
import {MatSort, Sort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {GenericTableDialogComponent} from '../generic-table-dialog/generic-table-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {DialogData} from '../../models/reporting/dialog-data.model';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit, AfterViewInit {

  @Input() columns: ColumnDefinition[];
  @Input() filterForm: FormGroup;
  @Input() parentSubject: Subject<any[]>;
  @Input() length: number;
  @Input() expandable: boolean;
  @Input() displayFilters: boolean;
  @Input() expandableVariableName: string;

  // Used for sub-table
  @Input() subTableColumn: ColumnDefinition[];
  @Input() loadDataCallback: Function;
  @Input() subTablePath: string;

  @Output() filterEmitter: EventEmitter<GenericFilterObject> = new EventEmitter<GenericFilterObject>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  genericFilterObj: GenericFilterObject = new GenericFilterObject();
  filters: ColumnDefinition[];

  reload = true;
  urlOpened = false;

  constructor(private dialog: MatDialog,
              public http: HttpClient,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.displayedColumns = this.columns.filter(d => d.isHeader === true).map(d => d.colDef);
    this.filters = this.columns.filter(d => d.isFilter === true);

    this.parentSubject.subscribe(
      data => {
        this.dataSource = new MatTableDataSource<any>(data);
        this.reload = false;
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilters() {
    this.paginator.pageIndex = 0;
    this.constructGenericFilterObj();

    this.reload = true;
    this.filterEmitter.emit(this.genericFilterObj);
  }

  get getColumnType() {
    return ColumnType;
  }

  onPageChange(event: PageEvent) {
    this.genericFilterObj.form = this.filterForm?.value;
    this.genericFilterObj.page = new Page(event.pageIndex, event.pageSize, this.getSortDetails(this.sort));
    this.reload = true;
    this.filterEmitter.emit(this.genericFilterObj);
  }

  constructGenericFilterObj() {
    this.genericFilterObj.form = this.filterForm?.value;
    this.genericFilterObj.page = new Page(0, this.paginator.pageSize, null);
    this.genericFilterObj.page.sort = this.getSortDetails(this.sort);
  }

  openDetails(row: any) {
    if (this.expandable) {
      if (row[this.expandableVariableName]?.length === 0) {
        this.openSnackBar();
        return;
      }

      if (!this.urlOpened) {
        const dialogData: DialogData = {
          columns: this.subTableColumn,
          loadDataCallback: this.loadDataCallback,
          ids: row[this.expandableVariableName],
          path: this.subTablePath,
          http: this.http
        };

        this.dialog.open(GenericTableDialogComponent, {
          data: dialogData,
          disableClose: true
        }).afterClosed().subscribe(response => { });
      }

      this.urlOpened = false;
    }
  }

  openSnackBar() {
    this.snackBar.open('User doesn\'t have customers assigned.', 'Close', {
      duration: 10 * 1000,
    });
  }

  changeSort(event: Sort) {
    const sort: string[] = this.getSortDetails(event);
    this.genericFilterObj.page = new Page(0, this.paginator.pageSize, sort);
    this.filterEmitter.emit(this.genericFilterObj);
  }

  getSortDetails(sortEvent: Sort): string[] {
    const sort = [];
    if (sortEvent.active === 'chainStore') {
      sort.push(sortEvent.direction === '' ? null : 'chain' + ',' + sortEvent.direction);
      sort.push(sortEvent.direction === '' ? null : 'str' + ',' + sortEvent.direction);
    } else if (sortEvent.active === 'cabPkt') {
      sort.push(sortEvent.direction === '' ? null : 'cab' + ',' + sortEvent.direction);
      sort.push(sortEvent.direction === '' ? null : 'pkt' + ',' + sortEvent.direction);
    } else {
      sort.push(sortEvent.direction === '' ? null : sortEvent.active + ',' + sortEvent.direction);
    }

    return sort;
  }

  openUrl(url: string) {
    this.urlOpened = true;
    window.open(url);
  }
}
