import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {GraphService} from '../graph/graph.service';
import {MsalGuard, MsalService} from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class GroupGuard implements CanActivate {

  constructor(private graphService: GraphService,
              private authService: MsalService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.graphService.user.displayName = accounts[0].idTokenClaims['preferred_username'];
      if (accounts[0].idTokenClaims['groups']) {
        this.graphService.user.groupIDs = <string[]>(
          (<unknown>accounts[0].idTokenClaims['groups'])
        );
      }

      const expectedGroup = route.data.expectedGroup;
      if (this.graphService.user.groupIDs.includes(expectedGroup)) {
        return true;
      }

      this.router.navigate(['/not-authorized']);
      return false;
    }

    return false;
  }
}
