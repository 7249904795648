import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Region} from '../../shared/constants/region';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  private subject = new Subject<Region>();

  constructor() { }

  switchLogo(switchObj: Region) {
    this.subject.next(switchObj);
  }

  getSwitchEvent(): Observable<Region> {
    return this.subject.asObservable();
  }
}
