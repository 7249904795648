export const COMPONENT_ROUTES = {
  CONNECTION_TEST: {
    PATH: 'connection-test',
    NAME: 'Test Connection',
    URL: '/connection-test'
  },
  USER_APP_CLIENT: {
    PATH: 'user-application-client',
    NAME: 'User Application Client',
    URL: '/user-app-client'
  },
  PTM_ASSIGNED_STORES: {
    PATH: 'ptm-assigned-stores',
    NAME: 'PTM\'s Assigned Stores',
    URL: '/ptm-assigned-stores'
  },
  REORDERS: {
    PATH: 'reorders',
    NAME: 'Reorders',
    URL: '/reorders'
  },
  NEGATIVE_ADJ: {
    PATH: 'negative-adjustments',
    NAME: 'Negative Adjustments',
    URL: '/adjustments/negative'
  },
  POSITIVE_ADJ: {
    PATH: 'positive-adjustments',
    NAME: 'Positive Adjustments',
    URL: '/adjustments/positive'
  },
  EMPTY_PKTS: {
    PATH: 'empty-pkts',
    NAME: 'Empty Pockets',
    URL: '/empty-pkts'
  },
  CREDITS: {
    PATH: 'credits',
    NAME: 'Credits',
    URL: '/credits'
  },
  TRANSMISSIONS: {
    PATH: 'transmissions',
    NAME: 'Transmissions',
    URL: '/transmissions'
  },
  SHOP_BACK_LOCATION: {
    PATH: 'shopback-loc',
    NAME: 'Shopback Location',
    URL: '/shopback-loc'
  },
  STOCK_COUNTS: {
    PATH: 'stock-counts',
    NAME: 'Stock Counts',
    URL: '/stock-counts'
  },
  CARTON_PUT_AWAY: {
    PATH: 'carton-put-away',
    NAME: 'Carton Put Away',
    URL: '/carton-put-away'
  },
  TASKS_SUMMARY: {
    PATH: 'summary',
    NAME: 'UK2GO Tasks Summary',
    URL: '/summary'
  },
  SHP_NOTIF: {
    PATH: 'delivery-confirmation',
    NAME: 'Delivery Confirmation',
    URL: '/delivery-confirmation'
  },
  ALL_STORES: {
    PATH: 'all-stores',
    NAME: 'All Stores',
    URL: '/all-stores'
  }
};
