import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {GenericFilterObject} from '../../../../shared/models/reporting/generic-filter-object';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';
import {ReorderService} from '../../../../core/services/reorder.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Reorder} from '../../../../shared/models/reporting/reorder.model';

@Component({
  selector: 'app-reorders',
  templateUrl: './reorders.component.html',
  styleUrls: ['./reorders.component.scss']
})
export class ReordersComponent implements OnInit, AfterViewInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.REORDERS.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: Reorder[] = [];
  length = 0;

  dataSubject: Subject<Reorder[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private msgBanner: MsgBannerService,
              public reorderService: ReorderService) {
    this.constructForm();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.openSnackBar();
    this.notifyChild([]);
  }

  constructForm() {
    this.form = this.fb.group({
      createdBy: [],
      usrNam: [],
      terr: [],
      chainStore: []
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.reorderService.getReorders(genericFilterObj, COMPONENT_ROUTES.REORDERS.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: Reorder[]) {
    this.dataSubject.next(data);
  }

  get getReorders() {
    return TABLE_CONTENTS.REORDERS;
  }

  get getReordersRoute() {
    return COMPONENT_ROUTES.REORDERS;
  }

  openSnackBar() {
    this.snackBar.open('Please filter your results.', 'Close', {
      duration: 10 * 1000,
    });
  }

}
