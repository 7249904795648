<mat-dialog-content
  fxLayout="column"
>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >User Details</div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close</mat-icon>
    </div>
  </div>

  <app-generic-table
    class="table-view"
    [columns]="dataDialog.columns"
    [displayFilters]="false"
    [parentSubject]="dataSubject" [length]="length"
    (filterEmitter)="refreshData($event)"
  ></app-generic-table>
</mat-dialog-content>
