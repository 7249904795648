import {Component, OnInit} from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GenericFilterObject, Page} from '../../../../shared/models/reporting/generic-filter-object';
import {UserService} from '../../../../core/services/user.service';
import {UserApplicationClient} from '../../../../shared/models/reporting/user-application-client.model';
import {Subject} from 'rxjs';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';

@Component({
  selector: 'app-user-app-client',
  templateUrl: './user-app-client.component.html',
  styleUrls: ['./user-app-client.component.scss']
})
export class UserAppClientComponent implements OnInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.USER_APP_CLIENT.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: UserApplicationClient[] = [];
  length = 0;

  dataSubject: Subject<UserApplicationClient[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private msgBanner: MsgBannerService,
              private userService: UserService) {
    this.constructForm();
  }

  ngOnInit(): void {
    const genericFilterObj: GenericFilterObject = {
      form: null,
      page: new Page(0, 10, null)
    };
    this.getData(genericFilterObj);
  }

  constructForm() {
    this.form = this.fb.group({
      usrId: [],
      nam: [],
      created: [],
      createdBy: [],
      updated: [],
      updatedBy: [],
      terr: [],
      cntry: []
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.userService.getUsers<UserApplicationClient>(genericFilterObj, COMPONENT_ROUTES.USER_APP_CLIENT.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: UserApplicationClient[]) {
    this.dataSubject.next(data);
  }

  getExpandable(): boolean {
    return TABLE_CONTENTS.USER_APP_CLIENT.EXPANDABLE;
  }
}
