import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColumnDefinition, TABLE_CONTENTS} from '../../../../shared/constants/table-contents';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {GenericFilterObject} from '../../../../shared/models/reporting/generic-filter-object';
import {COMPONENT_ROUTES} from '../../../../shared/constants/routes';
import {CartonPutAwayService} from '../../../../core/services/carton-put-away.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShipmentConfirmation} from '../../../../shared/models/reporting/shipment-confirmation.model';

@Component({
  selector: 'app-carton-put-away',
  templateUrl: './carton-put-away.component.html',
  styleUrls: ['./carton-put-away.component.scss']
})
export class CartonPutAwayComponent implements OnInit, AfterViewInit {

  columns: ColumnDefinition[] = TABLE_CONTENTS.CARTON_PUT_AWAY.DISPLAYED_COLUMNS;
  form: FormGroup;

  data: ShipmentConfirmation[] = [];
  length = 0;

  dataSubject: Subject<ShipmentConfirmation[]> = new Subject();

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private msgBanner: MsgBannerService,
              public cartonPutAwayService: CartonPutAwayService) {
    this.constructForm();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.openSnackBar();
    this.notifyChild([]);
  }

  constructForm() {
    this.form = this.fb.group({
      createdBy: [],
      usrNam: [],
      terr: [],
      chainStore: []
    });
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    this.cartonPutAwayService.getShipmentConfirmation(genericFilterObj, COMPONENT_ROUTES.CARTON_PUT_AWAY.URL).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.getData(event);
    }
  }

  notifyChild(data: ShipmentConfirmation[]) {
    this.dataSubject.next(data);
  }

  get getCartonPutAway() {
    return TABLE_CONTENTS.CARTON_PUT_AWAY;
  }

  get getCartonPutAwayRoute() {
    return COMPONENT_ROUTES.CARTON_PUT_AWAY;
  }

  openSnackBar() {
    this.snackBar.open('Please filter your results.', 'Close', {
      duration: 10 * 1000,
    });
  }
}
